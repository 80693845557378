.el-copytrading-tabs {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.el-copytrading-tabs-head {
  display: flex;
  gap: 12px;
}

.el-copytrading-tabs-head-tab {
  flex: 1;
}

.el-copytrading-tabs-head-tab-label {

}

.el-copytrading-tabs-bottom {
  display: none;
  opacity: 0;
  transition: opacity var(--transition-default);
  &.visible {
    opacity: 1;
    display: block;
  }
}

.el-copytrading-tabs-bottom-content-line {
  display: flex;
  gap: 12px;
  line-height: 21.6px;
  align-items: center;
}

.el-copytrading-tabs-bottom-content-line-left {
  flex: 1;

}

.el-copytrading-settings-wrapper {

}

.el-copytrading-settings-container {
  padding-top: 16px;
}

.el-copytrading-settings-section {
  background-color: #FFFFFF0A;
  border: 1px solid #0F1B2D80;
  padding: 20px;
  border-radius: 4px;
  color: var(--text-color)
}

.el-copytrading-settings-section-header {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  color: var(--text-color)
}

.el-copytrading-settings-section-header-separator {
  display: block;
  height: 2px;
  width: 36px;
  background-color: #5498F9;
}

.el-copytrading-form-item {
  max-width: 320px;
  &.widthFull {
    max-width: 320px;
  }
}
