.el-text {
  font-size: var(--el-text-font-size);
  &.ellipsed {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    line-height: inherit;
  }
  &.underline {
    text-decoration: underline;
  }
  &.w-500 {
    font-weight: 500;
  }
  &.w-600 {
    font-weight: 600;
  }
  &.w-700 {
    font-weight: 700;
  }
  &.block {
    display: block;
  }
  &.align-center {
    text-align: center;
  }
  &.clamped {
    display: -webkit-inline-box;
    -webkit-line-clamp: var(--el-text-clamp-count, 1);
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &.error {
    color: var(--red-color-muffled)
  }
  &.success {
    color: var(--green-color)
  }
}