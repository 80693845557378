.el-search-wrapper {
  // overflow: hidden;
  display: flex;
  align-items: center;
  gap: 16px;
  max-height: var(--el-search-max-height);
}

.el-search-input {
  flex: 1;
  font-size: 15px;
}

.el-search-left {
  display: flex;
  gap: 16px;
  align-items: center;
}

.el-search-result {
  font-size: 18px;
  line-height: 25px;
  color: var(--text-color);
  user-select: none;
}

.el-search-navigate {
  display: flex;
  gap: 4px
}

.el-search-item-highlighted {
  transition: filter var(--transition-default);
  filter: brightness(1.33);
}

.el-search-text-highlighted {
  transition: background-color var(--transition-default);
  background-color: rgba(255, 255, 0, .25);
}

.el-search-zero-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.el-search-zero-title {
  font-size: 24px;
  padding-bottom: 15%;
  color: #fff;
  display: inline-flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}

.el-search-inactive {
  opacity: 0.3;
  transition: all var(--transition-default);
  &:hover {
    opacity: 0.75
  }
}