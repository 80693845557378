.el-gradient {
  position: absolute;
  opacity: 1;
  transition: opacity var(--transition-default);
  display: none;
  height: var(--gradient-height);
  width: 100%;
  z-index: 4;
  opacity: 0;
  &.toTop {
    background: linear-gradient(0, var(--parent-background-color, var(--background-color)), transparent);
    bottom: 0;
  }
  &.toBottom {
    background: var(--parent-gradient, linear-gradient(180deg, var(--parent-background-color, var(--background-color)), transparent));
    bottom: calc(-1 * var(--gradient-height) + 1px);
  }
}