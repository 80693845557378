.el-toggle {
  position: relative;
  display: inline-block;
  width: var(--el-toggle-default-width);
  height: var(--el-toggle-default-height);
  &.round {
    .el-toggle-slider {
      border-radius: calc(var(--el-toggle-default-height / 2));
      &:before {
        border-radius: 50%;
      }
    }
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.el-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--el-toggle-default-background-color);
  box-shadow: inset 0px 0px 0px 1px var(--el-toggle-default-slider-color);
  -webkit-transition: var(--transition-default);
  transition: var(--transition-default);
  &:before {
    position: absolute;
    content: "";
    width: var(--el-toggle-slider-default-width);
    height: var(--el-toggle-slider-default-height);
    left: var(--el-toggle-slider-default-offset);
    bottom: var(--el-toggle-slider-default-offset);
    background-color: var(--el-toggle-default-slider-color);
    -webkit-transition: var(--transition-default);
    transition: var(--transition-default);
    box-shadow: 0px 0px 0px 1px #181C210A, 0px 1px 6px 0px #181C2117;
    z-index: 2;
  }
}

input {
  &:checked + .el-toggle-slider {
    background-color: var(--el-toggle-checked-slider-color);
    box-shadow: inset 0px 0px 0px 1px var(--el-toggle-checked-slider-color);
  }
  &:checked + .el-toggle-slider:before {
    -webkit-transform: translateX(calc(var(--el-toggle-default-width) - var(--el-toggle-slider-default-width) - 2 * var(--el-toggle-slider-default-offset)));
    -ms-transform: translateX(calc(var(--el-toggle-default-width) - var(--el-toggle-slider-default-width) - 2 * var(--el-toggle-slider-default-offset)));
    transform: translateX(calc(var(--el-toggle-default-width) - var(--el-toggle-slider-default-width) - 2 * var(--el-toggle-slider-default-offset)));
  }
  &:focus + .el-toggle-slider {
    box-shadow: 0 0 1px var(--el-toggle-checked-slider-color);
  }
}

.el-toggle-icon {
  position: absolute;
  top: 5px;
  z-index: 1;
  &.el-toggle-icon-left {
    left: 5px
  }
  &.el-toggle-icon-right {
    right: 5px
  }
}