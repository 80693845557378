.el-dots-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  &.withHPadding {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.el-dots {
  display: flex;
}

.el-dot {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 1px solid #5498F980;
  transition: background-color var(--transition-default), border-color var(--transition-default);
  &.active {
    border-color: #5498F9;
    background-color: #5498F9;
  }
}

.el-dot + .el-dot {
  margin-left: 14px;
}