.n-modal__modal .modal-content {
  max-width: 520rem !important;
  // min-height: 560rem !important;
  // max-height: 700rem !important;
  border-radius: 4rem !important;
}

.n-modal__modal-information .modal-content .n-modal {
  min-height: 560rem !important;
  max-height: 700rem !important;
}

.n-modal__modal-information .modal-dialog {
  max-width: 777rem !important;
}

.modal-content {
  background: rgba(255, 255, 255, 0.95) !important;
}

.n-modal {
  position: relative;
  background: rgba(255, 255, 255, 0.95) !important;
  border-radius: 4rem !important;

  &__vendor {
    &__content {
      max-height: 100vh;
    }

    &__card {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      &--2-column {
        & > * {
          flex: 48% 0 0;
        }
      }

      &--3-column {
        & > * {
          flex: 31.33% 0 0;
        }
      }

      &__center {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        text-align: left;
      }

      &__right {
        display: flex;
        justify-content: flex-end;

        & > * {
          margin-right: 6rem;
          font-weight: 400;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &__left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 10rem;
      }

      &__img {
        border: 1rem solid rgba(var(--colorRGB13), .5);
        height: 54rem;
        width: 72rem;
        border-radius: 8rem;
        margin-right: 16rem;
      }

      &__text {
        font-size: 15rem;
        font-weight: 600;
        line-height: 20rem;
        color: #0F1B2D;
        text-align: left;
      }
    }

    &-img {
      border: 1rem solid #0F1B2D4D;
      border-radius: 9rem;
      object-fit: cover;
    }
  }

  &__wrapper {
    margin: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 14rem;
    right: 14rem;
    cursor: pointer;

    svg {
      fill: var(--colorHEX5);
      opacity: 0.5;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  &__share {
    position: absolute;
    top: 14rem;
    left: 14rem;
    cursor: pointer;

    svg {
      fill: var(--colorHEX5);
      opacity: 0.5;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  &__like {
    position: absolute;
    top: 61rem;
    left: 14rem;
    cursor: pointer;

    svg {
      fill: var(--colorHEX5);
      opacity: 0.5;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  &__header {

    &--flex {
      display: flex;
      justify-content: space-between;
    }

    &--space-between {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      margin: 18rem 0;
      font-size: 28rem;
      line-height: 37rem;
      color: var(--colorHEX5);

      &--align-left {
        text-align: left;
      }
    }

    &__subtitle {
      font-weight: 400;
      font-size: 18rem;
      color: var(--colorHEX5);
      margin-bottom: 10rem;
    }

    &__subtitle_scrollable {
      font-weight: 400;
      font-size: 18rem;
      color: var(--colorHEX5);
      margin-bottom: 10rem;
      max-height: 290rem;
      overflow-y: scroll;
      text-align: justify;
      padding-right: 16rem;
    }

    &__title-red {
      margin: 18rem 0;
      font-size: 28rem;
      line-height: 37rem;
      color: #e30c0c;
    }

    &__icon {
      svg path {
        stroke: var(--colorHEX5) !important;
      }
    }
  }

  &__line {
    height: 1rem;
    width: 100%;
    background: rgba(var(--colorRGB8), 0.2);
    transform: matrix(1, 0, 0, -1, 0, 0);

    &--top-margin {
      margin-top: 18rem;
    }
  }

  &__content {
    overflow-y: auto;
    padding: 18rem 0;
    font-weight: 300;
    font-size: 18rem;
    line-height: 27rem;
    color: #4e5155;

    &--flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }

    &__list-block {
      * {
        text-align: left;
        font-size: 15rem;
        font-weight: 400;
        color: var(--colorHEX5);
        line-height: 150%;
      }

      &__description {
        font-weight: 700;
        margin-bottom: 28rem;
        font-size: 18rem;
      }

      ul {
        padding-left: 22rem;
        margin: 0;
      }

      li {
        list-style: none;
        position: relative;

        &:before {
          display: block;
          content: '';
          position: absolute;
          height: 4rem;
          width: 4rem;
          background-color: var(--colorHEX5);
          left: -14rem;
          border-radius: 100%;
          top: 10rem;
        }
      }
    }

    &__title {
      font-weight: 400;
      font-size: 18rem;
      color: var(--colorHEX5);
    }

    &__required {
      color: #ff0000;
      font-size: 12rem;
      line-height: 14rem;
      font-weight: 400;
      margin-top: 8rem;
    }

    &__title-sub {
      font-size: 12rem;
      line-height: 14rem;
      font-weight: 400;
      color: var(--colorHEX5);
      margin-top: 8rem;
    }

    &__optional {
      font-size: 12rem;
      line-height: 14rem;
      font-weight: 400;
      margin-top: 8rem;
    }

    &__subtitle {
      margin-top: 24rem;
    }

    & > .n-input {
      width: 100%;
    }

    &__input {
      margin-top: 24rem;
    }

    &__input-error {
      margin-top: 24rem;
    }

    &__input-text {
      display: none;
    }

    &__input-text-error {
      margin-top: 10rem;
      font-weight: 300;
      font-size: 18rem;
      line-height: 27rem;
      color: #e30c0c;
    }

    &__link {
      font-size: 18rem;
      line-height: 27rem;
      color: var(--colorHEX11);
    }

    &__user-added{
      display: flex;
      height: 40rem;
      align-items: center;
      border: 1rem solid var(--colorHEX8);
      padding: 0 10rem;
      background-color: #fff;
      margin-bottom: 10rem;
      text-transform: uppercase;
      box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.12);
      border-radius: 6rem;


      &:first-child{
        margin-bottom: 15rem;
      }

      &:last-child{
        margin-bottom: 0;
      }

      &>*{
        margin-right: 8rem;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  &__body {
    display: flex;

    &__br {
      width: 1rem;
      background: rgba(var(--colorRGB8), 0.2);
      transform: rotate(-180deg);
      margin-left: 28rem;
    }

    &__channel-card {
      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &--default {
          border: 1rem solid var(--colorHEX13);
          border-radius: 8rem;
          width: 192rem;
          height: 144rem;

          font-weight: 300;
          font-size: 100rem;
          line-height: 117rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: rgba(var(--colorRGB2), 0.3);

          &.paid {
            background: #7B61FF;
          }

          &.free {
            background: #39D6C7;
          }
        }

        img {
          border: 1rem solid var(--colorHEX13);
          border-radius: 8rem;
        }
      }

      &__name {
        padding: 18rem 0;

        &--subtitle {
          font-size: 18rem;
          line-height: 22rem;
          text-align: center;
          color: var(--colorHEX7);
        }

        &--title {
          font-weight: 700;
          font-size: 30rem;
          line-height: 37rem;
          text-align: center;
          color: var(--colorHEX7);
        }
      }

      &__paid {
        padding-top: 38rem;

        &--price {
          font-size: 30rem;
          line-height: 35rem;
          color: var(--colorHEX7);
          margin: 18rem 0rem;
        }

        &--trial {
          margin: 18rem 0rem;
          font-size: 18rem;
          line-height: 21rem;
          color: var(--colorHEX11);
          opacity: 0.5;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }

      &__free {
        padding: 110rem 0 40rem 0;
      }

      &__info {
        padding: 28rem 0 9rem 36rem;

        &__wrapper {
          align-items: center;
        }

        &__brief-description {
          font-weight: 700;
          font-size: 18rem;
          line-height: 27rem;
          color: var(--colorHEX5);
          align-items: flex-start;
          text-align: start;
          // padding-bottom: 45rem;
          height: 60rem;
          overflow-y: scroll;
        }

        &__description {
          font-size: 15rem;
          line-height: 22rem;
          color: var(--colorHEX5);
          text-align: initial;
          position: absolute;
          top: 180rem;
          bottom: 40rem;
          overflow-y: scroll;
          z-index: 1;
          margin-right: 40rem;
          z-index: 1;
        }

        &__one-description {
          font-size: 15rem;
          line-height: 22rem;
          color: var(--colorHEX5);
          text-align: initial;
          position: absolute;
          top: 35rem;
          bottom: 40rem;
          overflow-y: scroll;
          z-index: 1;
          margin-right: 40rem;
          z-index: 1;
        }
      }
    }
  }

  &__btns {
    margin-top: 24rem;

    &__wrapper {
      display: flex;
      align-items: flex-start;

      div {
        width: 100%;

        &:first-child {
          margin-right: 12rem;
        }
      }
    }

    &__confirm-red {
      cursor: pointer;
      border: 1rem solid #e30c0c;
      box-sizing: border-box;
      border-radius: 4rem;
      padding: 12rem 24rem;
      width: 100%;
      font-size: 20rem;
      line-height: 23rem;
      color: #e30c0c;

      &:hover {
        background: #e30c0c;
        color: #FFFFFF;
      }
    }

    &__confirm-save {
      cursor: pointer;
      border: 1rem solid var(--colorHEX8);
      background: var(--colorHEX11);
      box-sizing: border-box;
      border-radius: 4rem;
      padding: 12rem 24rem;
      width: 100%;
      font-size: 20rem;
      line-height: 23rem;
      color: #ffffff;
    }
  }
}

.is-opacity-30 {
  opacity: 0.3;
}


.n-modal__body__col-1, .n-modal__body__col-2 {
  float: left;
  width: 50%;
  height: 100%;
}

.n-modal__body__col-1 {
  // background: #10108d;
  // width: 320rem;
  // background-position: center center;
  // background-size: cover;
}

.n-modal__body__col-2 {
  // width: 320rem;
  // background: #000015;
}

.Tabs {
  width: 80%;
  height: auto;
  min-height: 400rem;
  background: #053742;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #E8F0F2;
  border-radius: 2rem;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  width: 80%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border: 1rem solid #39A2DB;
  border-radius: 2rem;
  padding-left: 0rem;
}

.react-tabs__tab {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  font-size: 18rem;
  transition: all 0.3s;
}

.react-tabs__tab--selected {
  color: var(--colorHEX11);
  border-bottom: 2rem solid var(--colorHEX11);
  background: none;
}

.react-tabs__tab--disabled {
  color: gray;
  cursor: default;
}

.react-tabs__tab:hover {
  color: var(--colorHEX8);
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  font-weight: 400;
  font-size: 16rem;
  color: var(--colorHEX5);
  margin-bottom: 10rem;
  height: 256rem;
  overflow-y: scroll;
  text-align: center;
  padding-right: 16rem;
}
