.el-input-range {
  .input-step{
    transform: translate(-100% 50%);
    position: absolute;
    bottom: 0;
    height: 10px;
    .bottom {      
      display: none;
    }
    .number {
      color: var(--colorHEX11);
      transform: translateX(-50%);
      min-height: 21rem;
    }
    &:after{
      //transform: translateX(-50%);
      content: '';
      position: absolute;
      top: -6.5px;
      background-color: var(--colorHEX11);
      height: 8rem;
      width: 2rem;
    }
  }
  .input-range__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5rem;
  }
  .input-range__title + div {
    width: 100%;
    height: 26px;
  }
  .input-range__title__num {
    font-weight: 400;
    font-size: 12rem;
    line-height: 14rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
  }
  .input-range__title__count {
    font-weight: 500;
    font-size: 18rem;
    line-height: 21rem;
    text-align: center;
    color: #FFFFFF;
  }
  input[type="range"] {
    -webkit-appearance: none;
    height: 4rem;
    width: 100%;
    background: transparent;
    border-radius: 2rem;
    background-image: linear-gradient(90deg, rgba(0,0,0,0) 12rem, rgba(78,141,232,1) 2%, rgba(78,141,232,1) 100%, rgba(0,0,0,0) 100%);
    background-repeat: no-repeat;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(90deg, rgba(0,0,0,0) 12px, var(--colorHEX5) 12px, var(--colorHEX5) calc(100% - 12px), rgba(0,0,0,0) calc(100% - 12px));
      z-index: -1;
      border-radius: 2rem;
    }
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 24rem;
    width: 24rem;
    position: relative;
    z-index: 10;
    //width: 2rem;
    border-radius: 50%;
    background: #fff;
    //transform: translateX(-50%);
    cursor: pointer;
    box-shadow: 0 0 2rem 0 #555;
    transition: background 0.3s ease-in-out;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}
