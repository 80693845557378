.copytrading-expanded {
  tr td, tfoot tr th, thead tr th {
    border: 1px transparent;
  }
  .filled-vendors {
    font-size: 15px;
    font-weight: 600;
    background-color: rgba(30, 112, 205, 0.15);
  }
  .filled-masters {
    font-weight: 500;
    background-color: rgba(30, 112, 205, 0.1);

  }
  .filled-slaves {
    background-color: rgba(30, 112, 205, 0.05);
  }
  div.row-expansion-style {
    padding: 0;
  }
  .table.table-bordered {
    margin-bottom: 0px;
  }

  .trades-table {
    padding: 8px;
    table {
      border-radius: 4px;
      // background-color: rgba(30, 112, 205, 0.06);
    }
  }

  .table-responsive {
    overflow-x: hidden;
  }

  tr {
    & > .filled-vendors:first-child, .filled-masters:nth-child(2), .filled-slaves:nth-child(3) {
      transition: transform 0.2s;
      position: relative;
      &:before {
        content: '';
        margin: 2.25px;
        margin-right: 12px;
        line-height: 43px;
        display: inline-block;
        width: 8px;
        -ms-flex-align: center;
        align-items: center;
        height: 8px;
        border: 1.5px solid #4e5155;
        border-right: 0;
        border-top: 0;
        transform: rotate(225deg);
        transition: transform 0.2s, -webkit-transform 0.2s;
      }
    }
    td {
      width: 25%;
    }
    td:last-child, td:nth-last-child(2) {
      width: 12.5%;
    }
  }

  .expanded {
    & > .filled-vendors:first-child, .filled-masters:nth-child(2), .filled-slaves:nth-child(3) {
      &:before {
        transform: rotate(315deg);
      }
    }
  }
}