.el-label {
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  color: #FFFFFFE5;
  text-align: left;
  user-select: none;
}

.el-form-row{
  &.el-form-row-disabled {
    opacity: 0.66;
    pointer-events: none;
  }
}