.el-unfold {
  overflow: hidden;
  height: 0px;
  opacity: 0;
  transition: height var(--transition-default), opacity var(--transition-default);
  animation-fill-mode: forwards;
  animation-duration: var(--animation-default);
  animation-delay: var(--animation-default);
  &.visible {
    height: var(--unfold-height);
    animation-name: unfold-fade-in;
  }
}

@keyframes unfold-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
