.el-icon {
  &.asAction {
    cursor: pointer;
    opacity: 0.75;
    transition: opacity var(--transition-default);
    &:hover {
      opacity: 1;
    }
  }
  &.asAction.active {
    opacity: 1;
  }
}

.el-icon-source-time {
  &.animating {
    path#source-time-left, path#source-time-right {
      transform-origin: center;
      animation-name: source-time;
      animation-duration: 2.25s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes source-time {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}