.el-scrollable {
  &.el-scrollable-hidden {
    // width: 100%;
    // height: 100%;
    overflow: hidden;
  }
  &.el-scrollable-stable-gutter {
    scrollbar-gutter: stable;
  }
  &.el-scrollable-overlay {
    overflow: overlay;
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
    z-index: 1;
    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
  &.el-scrollable-full {
    width: 100%;
    height: 100%;
  }
  &.el-scrollable-hidden-x {
    overflow-x: hidden;
  }
}