.el-button {
  cursor: pointer;
  text-align: initial;
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--default-font-size);
  gap: 12px;
  border: 0;
  user-select: none;
  background: transparent;
  &.inlineFlex {
    display: inline-flex;
    align-items: center;
    .el-button-center {
      flex: 1;
      display: inherit;
      align-items: center;
    }
  }
  &.widthFull {
    width: 100%;
  }
  &.heightFull {
    height: 100%;
  }
  &.aside {
    border-radius: var(--button-border-radius);
    // padding: 8px 8px;
    min-height: inherit;
    transition: background-color var(--transition-default);
    line-height: 24px;
    font-size: 14px;
    padding: 0px 8px;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    &.hovered {
      transition: gap var(--transition-long);
      &:hover {
        gap: 6px;
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
  &.asideTitle {
    font-size: 16px;
  }
  &.themePrimary {
    text-align: center;
    height: var(--el-button-default-height);
    min-height: 44px;
    color: var(--text-color);
    background: #4279C6;
    max-width: 350px;
    transition: filter var(--transition-default);
    border-radius: var(--button-border-radius);
    padding: 0 12px;
    &:hover {
      filter:brightness(1.1)
    }
    &:active {
      filter:brightness(1.2)
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.66;
    }
  }
  &.themeOutline {
    border-radius: var(--button-border-radius);
    text-align: center;
    height: var(--el-button-default-height);
    color: var(--text-color);
    box-shadow: 0px 0px 0px 1px var(--text-color) inset;
    background-color: rgba(255, 255, 255, 0);
    position: relative;
    z-index: 1;
    padding: 0 12px;
    &:before {
      content: "";
      transition: background-color var(--transition-default);
      position: absolute;
      inset: 0;
      background-color: rgba(255, 255, 255, 0);
    }
    &:hover:before {
      background-color: rgba(255, 255, 255, 0.1);
    }
    &:active:before {
      background-color: rgba(255,255, 255, 0.25);
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.66;
    }
  }
  &.themeTab {
    height: auto;
    padding: 4px 12px 4px 12px;
    border-radius: var(--button-border-radius);
    background-color: #4279C64D;
    box-shadow: 0px 2px 4px 0px #04070B40;
    text-align: center;
    transition: background-color var(--transition-quick);
    position: relative;
    &:before {
      content: "";
      transition: opacity var(--transition-default);
      position: absolute;
      inset: 0;
      opacity: 0;
      background-color: #FFFFFF1A;
    }
    &:hover:before {
      opacity: 1;
    }
    &.active {
      background-color: transparent;
      box-shadow: inset 0px 0px 0px 1px #264571;
      &:hover:before {
        opacity: 0;
      }
    }
  }
  &.asIconAction {
    opacity: 0.75;
    transition: opacity var(--transition-default);
    &:hover {
      opacity: 1;
    }
  }
}


.el-button-left {}
.el-button-right {}
