
.authentication-form {
  flex-shrink: 1;
}
.authentication-form__image {
  align-items: center;
  width: 300px;
  height: 100%;
}

.authentication-form__logo-name {
  padding-top: 60px;
  color: #000000;
  width: 400px;
  font-size: 24px;
  font-weight: 200;
  word-wrap: break-word;
}

.authentication-form__logo-description {
  padding-top: 15px;
  color: #000000;
  width: 400px;
  font-size: 20px;
  font-weight: 200;
  word-wrap: break-word;
}

.authentication-form__privacy-policy {
  padding-top: 60px;
  color: #000000;
  width: 400px;
  font-size: 18px;
  font-weight: 300;
  word-wrap: break-word;
  a { 
    color: #000000;
    text-decoration: none;
   }
   a:hover {
    color: #000000;
    text-decoration: underline;
   }
}

.authentication-form__bottom-logo {
  padding-top: 60px;
  font-weight: 200;
  font-size: 12px;
}
.authentication-form__bottom-description {
  & a>img {
    width: 64px;
    height: 100%;
  }
}