.el-input-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: inherit;
  &.left {
    input, textarea {
      padding-left: var(--input-padding-left);
    }
  }
  &.right {
    input, textarea {
      padding-right: var(--input-padding-left);
    }
  }
  textarea {
    resize: none;
    height: 56px;
  }
}

.el-input {
  padding: var(--input-padding);
  // border: 1px solid var(--input-border-color);
  border: 0;
  box-shadow: var(--input-box-shadow, var(--input-box-shadow-default));
  background-color: transparent;
  color: var(--text-color);
  width: inherit;
  height: inherit;
  font-size: inherit;
  line-height: 22px;
  border-radius: var(--border-radius-default);
  // box-shadow: 0px 4px 4px 0px #00000040 inset;
  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.notEmpty {   
    background-color: #FFFFFF1A;
  }
}

.el-input-left-wrapper, .el-input-right-wrapper {
  display: flex;
  height: 100%;
  width: 44px;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.el-input-left-wrapper {
  left: 0;
}

.el-input-right-wrapper {
  right: 0;
}

.el-textarea-div-hidden {
  padding: var(--input-padding);
  width: inherit;
  height: inherit;
  font-size: 15px;
  line-height: 22px;
  inset: 0;
  position: absolute;
  visibility: hidden;
  &.right {
    padding-left: var(--input-padding-left);
  }
  &.left {
    padding-right: var(--input-padding-right);
  }
}

.el-input-aside-new-ws {
  max-height: var(--el-input-aside-item-max-height);
}