.el-flex {
  display: flex;
  gap: var(--el-flex-gap, 0);
  &.el-flex-full {
    height: 100%;
    width: 100%;
  }
  &.el-flex-fullel-flex-full-horizonal {
    width: 100%;
  }
  &.el-flex-column {
    flex-direction: column;
  }
  &.el-flex-column-reverse {
    flex-direction: column-reverse;
  }
  &.el-flex-align-center {
    align-items: center;
  }
  &.el-flex-justify-center {
    justify-content: center;
  }
}