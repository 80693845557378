@import '../../styles/_custom-variables/libs';

$flatpickr-cell-size: 2.125rem !default;
$flatpickr-month-height: 1.75rem !default;
$flatpickr-week-height: 1.75rem !default;
$flatpickr-content-padding: .25rem !default;
$flatpickr-time-picker-height: 40px !default;
$flatpickr-animation-duration: 400ms !default;
$flatpickr-width: ($flatpickr-cell-size * 7)+($flatpickr-content-padding * 2);

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

.flatpickr-calendar {
  position: absolute;
  visibility: hidden;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  max-height: 0;
  border: 0;
  background: transparent;
  text-align: center;
  opacity: 0;
  animation: none;
  outline: 0;
  touch-action: manipulation;

  &.open,
  &.inline {
    visibility: visible;
    overflow: visible;
    max-height: 640px;
    opacity: 1;
  }

  &.open {
    display: inline-block;
  }

  &.animate.open {
    animation: fpFadeInDown 300ms cubic-bezier(.23, 1, .32, 1);
  }

  &:not(.inline):not(.open) {
    display: none !important;
  }

  &.inline {
    position: relative;
    top: 2px;
    display: block;
  }

  &.static {
    position: absolute;
    top: calc(100% + 2px);
  }

  &.static.open {
    z-index: 999;
    display: block;
  }

  &.hasWeeks {
    width: auto;
  }

  @include feature-ltr {
    &.hasWeeks .flatpickr-days {
      border-bottom-left-radius: 0 !important;
    }
  }

  @include feature-rtl {
    &.hasWeeks .flatpickr-days {
      border-bottom-right-radius: 0 !important;
    }
  }

  &.showTimeInput.hasTime .flatpickr-time {
    height: $flatpickr-time-picker-height;
  }

  &.noCalendar.hasTime .flatpickr-time {
    height: auto;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-month {
  position: relative;
  overflow: hidden;
  height: $flatpickr-month-height;
  text-align: center;
  line-height: 1;
  user-select: none;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  position: absolute;
  top: 0;
  z-index: 3;
  padding: 0 .5rem;
  height: $flatpickr-month-height;
  text-decoration: none;
  line-height: $flatpickr-month-height;
  opacity: .5;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }
}

.flatpickr-prev-month i,
.flatpickr-next-month i {
  position: relative;
}

.flatpickr-prev-month {
  &.flatpickr-prev-month {
    left: 0;
  }

  @include feature-rtl {
    right: 0;
    left: auto;
    transform: scaleX(-1);
  }
}

.flatpickr-next-month {
  &.flatpickr-prev-month {
    right: 0;
    left: 0;
  }

  &.flatpickr-next-month {
    right: 0;
  }

  @include feature-rtl {
    right: auto;
    left: 0;
    transform: scaleX(-1);
  }
}

.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  opacity: 1;
}

.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  width: 14px;
}

.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
  transition: fill .1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;

  input,
  span {
    display: inline-block;
  }

  input {
    width: 100%;
  }

  span {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 14px;
    height: 50%;
    line-height: 1;
    opacity: 0;
    cursor: pointer;

    @include feature-rtl {
      right: auto;
      left: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, .1);
    }

    &:active {
      background: rgba(0, 0, 0, .2);
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
    }

    &.arrowUp {
      top: 0;
    }

    &.arrowUp:after {
      border-right: 4px solid transparent;
      border-bottom: 4px solid rgba(72, 72, 72, .6);
      border-left: 4px solid transparent;
    }

    &.arrowDown {
      top: 50%;
    }

    &.arrowDown:after {
      border-top: 4px solid rgba(72, 72, 72, .6);
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    svg {
      width: inherit;
      height: auto;
    }

    svg path {
      fill: rgba(255, 255, 255, .5);
    }
  }

  &:hover {
    background: rgba(0, 0, 0, .05);
  }

  &:hover span {
    opacity: 1;
  }
}

.flatpickr-current-month {
  position: absolute;
  left: 12.5%;
  display: inline-block;
  width: 75%;
  height: $flatpickr-month-height;
  text-align: center;
  font-weight: 300;
  line-height: 1;
  padding: .25rem 0 0 0;
  transform: translate3d(0px, 0px, 0px);

  .flatpickr-monthDropdown-months,
  input.cur-year {
    padding: 0 0 0 .5ch;
    outline: none;
    vertical-align: middle !important;
    font-weight: 400;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
    display: inline-block;
    box-sizing: border-box;
    background: transparent;
    border: 0;
    border-radius: 0;
  }

  .numInputWrapper {
    display: inline-block;
    width: 6ch;
    width: 7ch\0;
  }

  .flatpickr-monthDropdown-months {
    appearance: menulist;
    cursor: pointer;
    height: $flatpickr-month-height - .25rem;
    // margin: -1px 0 0 0;
    position: relative;
    width: auto;
  }

  input.cur-year {
    margin: 0;
    height: $flatpickr-month-height - .25rem;
    cursor: default;

    @include feature-rtl {
      padding-right: .5ch;
      padding-left: 0;
    }

    &:focus {
      outline: 0;
    }

    &[disabled],
    &[disabled]:hover {
      background: transparent;
      pointer-events: none;
    }

    &[disabled] {
      opacity: .5;
    }
  }
}

.flatpickr-weekdaycontainer {
  display: flex;
  width: 100%;
}

.flatpickr-weekdays {
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: $flatpickr-week-height;
  text-align: center;
}

span.flatpickr-weekday {
  display: block;
  flex: 1;
  margin: 0;
  text-align: center;
  line-height: 1;
  cursor: default;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  display: flex;
  overflow: hidden;
  width: auto !important;

  &:focus {
    outline: 0;
  }

  .flatpickr-calendar.showTimeInput & {
    border-bottom: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.dayContainer {
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0;
  min-width: $flatpickr-cell-size * 7;
  max-width: $flatpickr-cell-size * 7;
  width: $flatpickr-cell-size * 7;
  outline: 0;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-day {
  position: relative;
  display: inline-block;
  flex-basis: 14.2857143%;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  max-width: $flatpickr-cell-size;
  width: 14.2857143%;
  height: $flatpickr-cell-size;
  border: 1px solid transparent;
  background: none;
  text-align: center;
  font-weight: 400;
  line-height: calc(#{$flatpickr-cell-size} - 2px);
  cursor: pointer;

  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    outline: 0;
    cursor: pointer;
  }

  &.inRange:not(.startRange):not(.endRange) {
    border-radius: 0 !important;
  }

  &.disabled,
  &.disabled:hover {
    border-color: transparent;
    background: transparent;
    cursor: default;
    pointer-events: none;
  }

  &.prevMonthDay,
  &.nextMonthDay {
    border-color: transparent;
    background: transparent;
    cursor: default;
  }

  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
    border-color: transparent;
    background: transparent;
    cursor: default;
  }

  &.week.selected {
    border-radius: 0;
  }

  @include feature-ltr {
    &.selected.startRange,
    &.startRange.startRange,
    &.endRange.startRange {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.selected.endRange,
    &.startRange.endRange,
    &.endRange.endRange {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  @include feature-rtl {
    &.selected.startRange,
    &.startRange.startRange,
    &.endRange.startRange {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.selected.endRange,
    &.startRange.endRange,
    &.endRange.endRange {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;

  .flatpickr-weeks {
    padding: $flatpickr-content-padding 0;
    background-clip: padding-box !important;

    @include feature-ltr {
      .flatpickr-weeks {
        border-bottom-right-radius: 0 !important;
      }
    }

    @include feature-rtl {
      .flatpickr-weeks {
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  .flatpickr-calendar.showTimeInput .flatpickr-weeks {
    border-bottom: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: $flatpickr-week-height;
  }

  span.flatpickr-day {
    display: block;
    max-width: none;
    width: $flatpickr-cell-size;
    background: none !important;
  }
}

.flatpickr-calendar.hasTime.showTimeInput .flatpickr-weeks {
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
}

.flatpickr-rContainer {
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}

.flatpickr-time {
  display: block;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  max-height: $flatpickr-time-picker-height;
  height: 0;
  outline: 0;
  background-clip: padding-box !important;
  text-align: center;
  line-height: $flatpickr-time-picker-height;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .numInputWrapper {
    float: left;
    flex: 1;
    width: 40%;
    height: $flatpickr-time-picker-height;
  }

  &.hasSeconds .numInputWrapper {
    width: 26%;
  }

  &.time24hr .numInputWrapper {
    width: 49%;
  }

  input {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: inherit;
    border: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    text-align: center;
    line-height: inherit;
    cursor: pointer;

    &.flatpickr-minute,
    &.flatpickr-second {
      font-weight: normal;
    }

    &:focus {
      outline: 0;
      border: 0;
    }
  }

  .flatpickr-time-separator,
  .flatpickr-am-pm {
    display: inline-block;
    float: left;
    align-self: center;
    width: 2%;
    height: inherit;
    line-height: inherit;
    user-select: none;
  }

  .flatpickr-am-pm {
    width: 18%;
    outline: 0;
    text-align: center;
    font-weight: normal;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, .05);
    }
  }

  .flatpickr-calendar.noCalendar & {
    box-shadow: none !important;
  }

  .flatpickr-calendar:not(.noCalendar) & {
    border-top: 0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

// Animations
//

@include keyframes(fpFadeInDown) {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@if $enable-light-style {
  .light-style {
    @import "../../styles/_platform/include";

    // Dimensions
    .flatpickr-calendar,
    .flatpickr-days {
      width: calc(#{$flatpickr-width} + #{$dropdown-border-width * 2}) !important;
    }

    .flatpickr-calendar.hasWeeks {
      width: calc(#{$flatpickr-width + $flatpickr-cell-size} + #{$dropdown-border-width * 3}) !important;
    }

    .flatpickr-calendar.open {
      z-index: $zindex-popover;
    }

    .flatpickr-input + .form-control.input[readonly] {
      background: $input-bg;
    }

    .flatpickr-days {
      padding: $flatpickr-content-padding;
      border: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      border-top: 0;
      background: $dropdown-bg;
      background-clip: padding-box;

      @include border-bottom-radius($border-radius);
    }

    @include feature-ltr-style {
      .flatpickr-calendar.hasWeeks .flatpickr-days {
        padding-left: calc(#{$flatpickr-content-padding} + #{$dropdown-border-width});
        border-left: 0;
        box-shadow: $dropdown-border-width 0 0 opacify($dropdown-border-color, .05) inset;
      }
    }

      @include feature-rtl-style {
        .flatpickr-calendar.hasWeeks .flatpickr-days {
          padding-right: calc(#{$flatpickr-content-padding} + #{$dropdown-border-width});
          border-right: 0;
          box-shadow: -$dropdown-border-width 0 0 opacify($dropdown-border-color, .05) inset;
        }
      }

    .flatpickr-calendar {
      box-shadow: $floating-component-shadow;
      font-size: $font-size-base;
      line-height: $line-height-base;

      @include border-radius($border-radius);

      &.showTimeInput.hasTime .flatpickr-time {
        box-shadow: 0 1px 0 $border-color inset;
      }

      &.hasTime:not(.noCalendar):not(.showTimeInput) .flatpickr-time {
        display: none !important;
      }
    }

    .flatpickr-month {
      @include border-top-radius($border-radius);
    }

    .flatpickr-current-month {
      font-size: $big-font-size;
    }

    .flatpickr-monthDropdown-months option {
      color: $body-color;
    }

    span.flatpickr-weekday {
      font-weight: $font-weight-semibold;
      font-size: $font-size-sm;
    }

    .flatpickr-day {
      color: $body-color;

      @include border-radius($border-radius);

      &:hover,
      &:focus,
      &.prevMonthDay:hover,
      &.nextMonthDay:hover,
      &.today:hover,
      &.prevMonthDay:focus,
      &.nextMonthDay:focus,
      &.today:focus {
        border-color: transparent;
        background: $gray-100;
        color: $body-color;
      }

      &.prevMonthDay,
      &.nextMonthDay {
        color: $text-light;
      }

      &.flatpickr-disabled {
        color: $text-light !important;
      }

      &.selected.startRange.endRange {
        border-radius: $border-radius !important;
      }
    }

    .flatpickr-weeks {
      border-bottom: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      border-left: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      background: rgba-to-hex($gray-50, $dropdown-bg);

      @include border-bottom-radius($border-radius);
      border-bottom-right-radius: 0;
    }

    @include feature-rtl-style {
      .flatpickr-weeks {
        border-right: $dropdown-border-width solid opacify($dropdown-border-color, .05);
        border-left: 0;

        @include border-bottom-radius($border-radius);
        border-bottom-left-radius: 0;
      }
    }

    .flatpickr-time {
      border: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      background: $dropdown-bg;

      @include border-radius($border-radius);

      .numInputWrapper span {
        &.arrowUp:after {
          border-bottom-color: $text-muted;
        }

        &.arrowDown:after {
          border-top-color: $text-muted;
        }
      }

      input {
        color: $body-color;
        font-size: $font-size-base;

        &.flatpickr-hour {
          font-weight: bold;
        }

        &.flatpickr-minute,
        &.flatpickr-second {
          font-weight: $font-weight-semibold;
        }
      }

      .flatpickr-time-separator {
        color: $body-color;
        font-weight: $font-weight-semibold;
      }

      .flatpickr-am-pm {
        color: $body-color;
      }
    }
  }
}

@if $enable-material-style {
  .material-style {
    @import "../../styles/_platform/include-material";

    .flatpickr-calendar,
    .flatpickr-days {
      width: calc(#{$flatpickr-width} + #{$dropdown-border-width * 2}) !important;
    }

    .flatpickr-calendar.hasWeeks,
    .flatpickr-calendar.hasWeeks .flatpickr-days {
      width: calc(#{$flatpickr-width + $flatpickr-cell-size} + #{$dropdown-border-width * 3}) !important;
    }


    .flatpickr-calendar.open {
      z-index: $zindex-popover;
    }

    .flatpickr-input + .form-control.input[readonly] {
      background: $input-bg;
    }

    .flatpickr-days {
      padding: $flatpickr-content-padding;
      border: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      border-top: 0;
      background: $dropdown-bg;
      background-clip: padding-box;

      @include border-bottom-radius($border-radius);
    }

    @include feature-ltr-style {
      .flatpickr-calendar.hasWeeks .flatpickr-days {
        padding-left: calc(#{$flatpickr-content-padding} + #{$dropdown-border-width});
        border-left: 0;
        box-shadow: $dropdown-border-width 0 0 opacify($dropdown-border-color, .05) inset;
      }
    }

    @include feature-rtl-style {
      .flatpickr-calendar.hasWeeks .flatpickr-days {
        padding-right: calc(#{$flatpickr-content-padding} + #{$dropdown-border-width});
        border-right: 0;
        box-shadow: -$dropdown-border-width 0 0 opacify($dropdown-border-color, .05) inset;
      }
    }

    .flatpickr-calendar {
      box-shadow: $floating-component-shadow;
      font-size: $font-size-base;
      line-height: $line-height-base;

      @include border-radius($border-radius);

      &.showTimeInput.hasTime .flatpickr-time {
        box-shadow: 0 1px 0 $border-color inset;
      }

      &.hasTime:not(.noCalendar):not(.showTimeInput) .flatpickr-time {
        display: none !important;
      }
    }

    .flatpickr-month {
      @include border-top-radius($border-radius);
    }

    .flatpickr-current-month {
      font-size: $big-font-size;
    }

    .flatpickr-monthDropdown-months option {
      color: $body-color;
    }

    span.flatpickr-weekday {
      font-weight: $font-weight-semibold;
      font-size: $font-size-sm;
    }

    .flatpickr-day {
      color: $body-color;
      font-weight: $font-weight-semibold;
      font-size: $font-size-sm;

      @include border-radius(10rem);

      &:hover,
      &:focus,
      &.prevMonthDay:hover,
      &.nextMonthDay:hover,
      &.today:hover,
      &.prevMonthDay:focus,
      &.nextMonthDay:focus,
      &.today:focus {
        border-color: transparent;
        background: $gray-100;
        color: $body-color;
      }

      &.prevMonthDay,
      &.nextMonthDay {
        color: $text-light;
      }

      &.flatpickr-disabled {
        color: $text-light !important;
      }

      &.selected.startRange.endRange {
        border-radius: 10rem !important;
      }
    }

    .flatpickr-weeks {
      border-bottom: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      border-left: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      background: rgba-to-hex($gray-50, $dropdown-bg);

      @include border-bottom-radius($border-radius);
    }

    @include feature-rtl-style {
      .flatpickr-weeks {
        border-right: $dropdown-border-width solid opacify($dropdown-border-color, .05);
        border-left: 0;
      }
    }

    .flatpickr-time {
      border: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      background: $dropdown-bg;

      @include border-radius($border-radius);

      .numInputWrapper span {
        &.arrowUp:after {
          border-bottom-color: $text-muted;
        }

        &.arrowDown:after {
          border-top-color: $text-muted;
        }
      }

      input {
        color: $body-color;
        font-size: $font-size-base;

        &.flatpickr-hour {
          font-weight: bold;
        }

        &.flatpickr-minute,
        &.flatpickr-second {
          font-weight: $font-weight-semibold;
        }
      }

      .flatpickr-time-separator {
        color: $body-color;
        font-weight: $font-weight-semibold;
      }

      .flatpickr-am-pm {
        color: $body-color;
      }
    }
  }
}

@if $enable-dark-style {
  .dark-style {
    @import "../../styles/_platform/include-dark";

    // Dimensions
    .flatpickr-calendar,
    .flatpickr-days {
      width: calc(#{$flatpickr-width} + #{$dropdown-border-width * 2}) !important;
    }

    .flatpickr-calendar.hasWeeks {
      width: calc(#{$flatpickr-width + $flatpickr-cell-size} + #{$dropdown-border-width * 3}) !important;
    }

    .flatpickr-calendar.open {
      z-index: $zindex-popover;
    }

    .flatpickr-input + .form-control.input[readonly] {
      background: $input-bg;
    }

    .flatpickr-days {
      padding: $flatpickr-content-padding;
      border: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      border-top: 0;
      background: $dropdown-bg;
      background-clip: padding-box;

      @include border-bottom-radius($border-radius);
    }

    @include feature-ltr-style {
      .flatpickr-calendar.hasWeeks .flatpickr-days {
        padding-left: calc(#{$flatpickr-content-padding} + #{$dropdown-border-width});
        border-left: 0;
        box-shadow: $dropdown-border-width 0 0 opacify($dropdown-border-color, .05) inset;
      }
    }

    @include feature-rtl-style {
      .flatpickr-calendar.hasWeeks .flatpickr-days {
        padding-right: calc(#{$flatpickr-content-padding} + #{$dropdown-border-width});
        border-right: 0;
        box-shadow: -$dropdown-border-width 0 0 opacify($dropdown-border-color, .05) inset;
      }
    }

    .flatpickr-calendar {
      box-shadow: $floating-component-shadow;
      font-size: $font-size-base;
      line-height: $line-height-base;

      @include border-radius($border-radius);

      &.showTimeInput.hasTime .flatpickr-time {
        box-shadow: 0 1px 0 $border-color inset;
      }

      &.hasTime:not(.noCalendar):not(.showTimeInput) .flatpickr-time {
        display: none !important;
      }
    }

    .flatpickr-month {
      @include border-top-radius($border-radius);
    }

    .flatpickr-current-month {
      font-size: $big-font-size;
    }

    .flatpickr-monthDropdown-months option {
      color: $black;
    }

    span.flatpickr-weekday {
      font-weight: $font-weight-semibold;
      font-size: $font-size-sm;
    }

    .flatpickr-day {
      color: $body-color;

      @include border-radius($border-radius);

      &:hover,
      &:focus,
      &.prevMonthDay:hover,
      &.nextMonthDay:hover,
      &.today:hover,
      &.prevMonthDay:focus,
      &.nextMonthDay:focus,
      &.today:focus {
        border-color: transparent;
        background: $gray-100;
        color: $body-color;
      }

      &.prevMonthDay,
      &.nextMonthDay {
        color: $text-light;
      }

      &.flatpickr-disabled {
        color: $text-light !important;
      }

      &.selected.startRange.endRange {
        border-radius: $border-radius !important;
      }
    }

    .flatpickr-weeks {
      border-bottom: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      border-left: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      background: rgba-to-hex($gray-50, $dropdown-bg);

      @include border-bottom-radius($border-radius);
      border-bottom-right-radius: 0;
    }

    @include feature-rtl-style {
      .flatpickr-weeks {
        border-right: $dropdown-border-width solid opacify($dropdown-border-color, .05);
        border-left: 0;

        @include border-bottom-radius($border-radius);
        border-bottom-left-radius: 0;
      }
    }

    .flatpickr-time {
      border: $dropdown-border-width solid opacify($dropdown-border-color, .05);
      background: $dropdown-bg;

      @include border-radius($border-radius);

      .numInputWrapper span {
        &.arrowUp:after {
          border-bottom-color: $text-muted;
        }

        &.arrowDown:after {
          border-top-color: $text-muted;
        }
      }

      input {
        color: $body-color;
        font-size: $font-size-base;

        &.flatpickr-hour {
          font-weight: bold;
        }

        &.flatpickr-minute,
        &.flatpickr-second {
          font-weight: $font-weight-semibold;
        }
      }

      .flatpickr-time-separator {
        color: $body-color;
        font-weight: $font-weight-semibold;
      }

      .flatpickr-am-pm {
        color: $body-color;
      }
    }
  }
}
