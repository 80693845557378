.el-content-container {
  padding: var(--el-content-container-padding, 32px);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: var(--parent-background-color);
  &.withoutPaddingBottom {
    padding-bottom: 0;
  }
  &.el-content-container-error {
    box-shadow: 0px 0px 0px 1px var(--red-color-muffled) inset;
  }
  &.el-content-container-error-flash {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0;
      opacity: 0;
      animation: el-content-error-flash-animation var(--animation-long);
      background-color: var(--red-color-muffled);
    }
    .el-gradient {
      opacity: 0;
      transition: opacity var(--transition-quick);
    }
  }
}

@keyframes el-content-error-flash-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.el-content-wrapper {
  overflow: overlay;
}

.el-content-title, .el-content-title-breadcrumb {
  font-size: 24px;
  color: white;
  text-transform: capitalize;
  flex: 1;
  overflow: hidden;
  text-decoration: none;
}

.el-content-title-breadcrumb {
  &.clickable {
    cursor: pointer;
    color: white;
    text-decoration: underline;
  }
}

.el-content-title-breadcrumb-divider {
  display: inline-block;
  margin: 0 8px;
}

.el-content-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // padding-bottom: 16px;
  gap: 16px;
  min-height: 46px;
}

.el-content-widgets {
  padding: 16px 10px 24px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--wwidget-min-width), 1fr));
  gap: 24px
}

.el-content-action {
  min-width: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.el-content-top {
  display: flex;
  flex-direction: column;
  position: relative;
}

.el-content-widgets, .el-content-store {
  padding: 16px 10px 24px;
}

.el-content-store {
  display: grid;
  grid-template-columns: repeat(2, var(--store-block-width));
  grid-template-rows: repeat(2, var(--store-block-height));
  gap: 48px;
  justify-content: center;
}

.el-content-item {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border: 1px solid #5498F94D;
  background-color: #203B60;
  border-radius: var(--border-radius-default);
  transition: background-color var(--transition-default);
  height: 100%;
  &:hover {
    background-color: var(--store-block-hovered);
  }
}

.el-content-item-icon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #FFFFFF80;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.el-content-item-title {
  font-family: Noto Sans Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--text-color)
}

.el-content-item-description {
  font-family: Noto Sans Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  color: #FFFFFF80
}

.el-content-item-button {
  width: 100%;
}

.el-content-copytrading {
  width: 100%;
  height: 100%;
  &.disabled {
    pointer-events: none;
    position: relative;
    z-index: 1;
    opacity: 0.3;
  }
}