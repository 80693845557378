.rrich-message {
  .modal-content {
    height: 730rem!important;
    width: 890rem!important;
    left: 50%;
    top: 400rem;
    transform: translate(-50%,-50%);
  }
  &__wrapper {
    align-items: center;
    padding: 15rem;
  }
  &__body {
    &__show {
      display: block;
    }
    &__close {
      display: none;
    }
  }
  &__header {
    height: 41rem;
    margin-bottom: 8rem;
    display: flex;
    justify-content: space-between;
    &__title {
      font-weight: 300;
      font-size: 36rem;
      line-height: 39rem;
      color: #4E5155;
      cursor: default;
      &--close {
        display: none;
      }
      &--bold {
        font-weight: 500;
      }
    }
    &__preview {
      font-weight: 300;
      font-size: 36rem;
      line-height: 39rem;
      color: #4E5155;
      cursor: default;
      &--close {
        display: none;
      }
      &__bold {
        font-weight: 500;
      }
      &__bath {
        color: #8CAEDB;
        font-weight: 500;
      }
    }
  }
  &__signals {
    height: 77rem;
    margin-bottom: 8rem;
    &__triangle-left {
      width: 0;
      height: 0rem;
      border-top: 16rem solid transparent;
      border-bottom: 16rem solid transparent;
      border-right: 8rem solid #DDDDDD;
      cursor: pointer;
      &:hover {
        border-right: 8rem solid #adabab;
      }
    }
    &__triangle-right {
      width: 0;
      height: 0;
      border-top: 16rem solid transparent;
      border-bottom: 16rem solid transparent;
      border-left: 8rem solid #DDDDDD;
      cursor: pointer;
      &:hover {
        border-left: 8rem solid #adabab;
      }
    }  
    &__cards {
      width: 828rem;
    }
    &__card {
      height: 77rem;
      width: 204rem;
      &__wrapper {
        align-items: center;
        padding: 5rem;
      }
      &__img {
        height: 59rem;
        width: 91rem;
        max-height: 100%;

        img{
          max-height: 100%;
        }
      }
      &__aside {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 57rem;
        &__title {
          font-size: 14rem;
          font-weight: 500;
          color: #888;
        }
        &__price {
          font-size: 10rem;
          color: #02BC77;
        }
      }
    }
  }
  &__user-template {
    height: 38rem;
    width: 570rem;
    margin-bottom: 8rem;
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__inputs {
        display: flex;
      }
      &__input {
        width: 450rem;
        height: calc(1.54em + 0.876rem + 2rem);
        padding: 1.11rem 0.875rem;
        font-size: 0.894rem;
        font-weight: 400;
        line-height: 1.54;
        color: #4E5155;
        background-color: #fff;
        background-clip: padding-box;
        // border: 1rem solid rgba(24, 28, 33, 0.1);
        border-top: 2rem solid rgba(24, 28, 33, 0.1) !important;
        border-right: 2rem solid rgba(24, 28, 33, 0.1) !important;
        border-bottom: 2rem solid rgba(24, 28, 33, 0.1) !important;
        border-left:  2rem solid rgba(24, 28, 33, 0.1) !important;
        border-radius: 0 0.25rem 0.25rem 0 !important;
      }
    }
    &__select {
      width: 450rem;
      border-top: 1rem solid rgba(24, 28, 33, 0.1) !important;
      border-right: 1rem solid rgba(24, 28, 33, 0.1) !important;
      border-bottom: 1rem solid rgba(24, 28, 33, 0.1) !important;
      border-left:  1rem solid rgba(24, 28, 33, 0.1) !important;
      border-radius: 0.25rem 0 0 0.25rem !important;
      .react-select__control {
        border-radius: 0.25rem 0 0 0.25rem !important;
        border-top: 1rem solid rgba(24, 28, 33, 0.1) !important;
        border-right: 1rem solid rgba(24, 28, 33, 0.1) !important;
        border-bottom: 1rem solid rgba(24, 28, 33, 0.1) !important;
        border-left:  1rem solid rgba(24, 28, 33, 0.1) !important;
      }
      & > .custom-select {
        color: #999 !important;
      }
    }
    &__check {
      display: flex;
      align-items: center;
      padding-top: 5rem;
      color: #d1d1d1;
    }
  }
  &__message {
    height: 474rem;
    width: 570rem;
    background: #F0F0F0;
    margin-bottom: 8rem;
    border-radius: 0.25rem;
    overflow-y: auto;
    &__card {
      &__title {
        font-weight: bold;
        font-size: 18rem;
        color: #AAAAAA;
        text-align: center;
        height: 34rem;
        padding-top: 4rem;
        &--additional {
          height: auto !important;
        }
      }
      &__body {
        height: 374rem;
        width: 544rem;
        margin: 0 13rem 0 13rem;
        padding: 10rem;
        overflow-y: auto;
        &__background {
          background: #ffff;
        }
        &__title {
          font-size: 20rem;
          align-items: center;
          color: #939393;
        }
        &__wrapper {
          padding: 0 16rem 0 24rem;
        }
        &__message {
          display: flex;
          padding-top: 10rem;
          padding-bottom: 10rem;
          &__text {
            padding-left: 10rem;
            font-size: 18rem;
            font-weight: 300;
            color: #939393;
          }
          &__select {
            padding-left: 10rem;
            .custom-select {
              height: 28rem;
              width: 270rem;
              border: 1rem solid rgba(24, 28, 33, 0.1);
              /* 333 */
              padding: 0.038rem 2.75rem 0.438rem 0.65rem;
              // padding-left: 26rem;
            }
          }
        }
        &__color-card {
          display: flex;
          padding-bottom: 3rem;
          &__text {
            padding-left: 10rem;
            font-size: 18rem;
            font-weight: 300;
            color: #939393;
          }
          &__picker {
            padding-top: 2rem;
            padding-left: 10rem;
          }
        }
        &__title-card {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 10rem;
          &__text {
            padding-left: 12rem;
            // padding-top: 3rem;
            font-size: 18rem;
            font-weight: 300;
            color: #939393;
          }
          &__edit {
            padding-left: 2rem;
            padding-top: 0rem;
            font-size: 21rem;
            font-weight: 400;
            color: #939393;
            border: 0!important;
            & > input {
              border: 0!important;
              color: #939393;
            }
            & > input:focus::-webkit-input-placeholder {
              color: transparent!important;
            }
            & > input:focus::-moz-placeholder {
              color: transparent!important;
            }
            & > input:focus:-moz-placeholder {
                color: transparent!important;
            }
            &__text {
              border: 0;
              border-bottom-width: 1rem;
              border-bottom-style: dashed;
              border-bottom-color: #AAAAAA!important;
              font-size: 18rem;
              font-weight: 300;
              color: #939393!important;
              height: 25rem!important;
              width: 400rem!important;
              padding: 0;
              line-height: none;
            }
          }
        }
        &__table-card {
          padding-bottom: 10rem;
          &__wrapper {
            display: flex;
          }
          &__text {
            padding-left: 13rem;
            padding-bottom: 8rem;
            font-size: 18rem;
            font-weight: 300;
            color: #939393;
          }
          &__table {
            display: flex;
            padding-left: 35rem;
            &__wrapper {
              display: flex;
            }
            &__input {
              width: 451rem;
              padding: 0 5rem 5rem 0;
              & .form-control {
                width: 209rem;
                height: 32rem;
              }
            }
            &__delete {
              padding-top: 8rem;
            }
            &__add {
              display: flex;
              flex-direction: row;
              justify-content: center;
              padding-right: 24rem;
            }
            // font-size: 18rem;
            // font-weight: 300;
            // color: #939393;
          }
        }
        &__image-card {
          display: flex;
          padding-bottom: 10rem;
          &__icon {
            padding-right: 14rem;
          }
          &__wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 4rem;
            padding-right: 4rem;
            font-size: 18rem;
            font-weight: 300;
            color: #939393;
            border: 2rem;
            border-style: dashed;
            border-color: #eee;
            border-radius: 3rem;
          }
          &__text {
            // padding-left: 16rem;
            font-size: 18rem;
            font-weight: 300;
            color: #939393;
          }
          &__edit {
            padding-left: 10rem;
            font-size: 18rem;
            font-weight: 300;
            color: #939393;
          }
        }
        &__text-card {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding-bottom: 10rem;
          &__text {
            padding-left: 10rem;
            font-size: 18rem;
            font-weight: 300;
            color: #939393;
          }
          &__text-comment {
            width: 450rem;
            padding-left: 12rem;
            &textarea {
              height: 84rem;
              width: 450rem!important;
              font-size: 18rem;
              font-weight: 300;
              color: #939393;
            }
          }
        }
      }
      &__add {
        text-align: center;
        padding-top: 5rem;
        height: 34rem;
        &__btn {
          cursor: pointer;
        }
      }
    }
  }
  &__composing-mode {
    height: 41rem;
    width: 570rem;
    border: 1rem solid #ecebed;
    border-radius: 0.25rem;
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 0 10rem 0 10rem;
      height: 41rem;
    }
    &__aside-left {
      display: flex;
      align-items: center;
      height: 41rem;
      &__title {
        font-weight: 300;
        font-size: 18rem;
        color: #888;
        cursor: default;
      }
      &_chek {
        padding-left: 20rem;
        padding-top: 10rem;
      }
    }
    &__aside-right {
      padding-top: 5rem;
    }
  }
  &__send-time {
    height: 520rem;
    width: 271rem;
    margin-bottom: 8rem;
    border: 1rem solid #ecebed;
    border-radius: 0.25rem;
    &__send {
      padding: 15rem 10rem;
      
      & > .custom-control {
        margin-bottom: 0 !important;
      }

      &:hover {
        background: #ecebed;
      }
    }
    &__loop {
      padding: 15rem 10rem;
      // &:hover {
      //   background: #ecebed;
      // }
      &__interval {
        display: flex;
        &__input {
          .form-control-sm {
              width: 37rem;
          }
        }
        &__subtext {
          padding-top: 1rem;
          padding-left: 16rem;
        }
      }
      &__loop-repeat {
        padding-left: 25rem;
      }
    }
    &__top {
      padding: 10rem;
      &:hover {
        background: #ecebed;
      }
    }
    &__time {
        display: flex;
        padding: 15rem 10rem;
        // &:hover {
        //   background: #ecebed;
        // }
      &__title {
        padding-top: 8rem;
      }
      &__time-input {
        width: 156rem;
      }
    }
    &__date {
      padding: 10rem;
      &__input {
        .react-datepicker {
          width: 248rem;
        }
      }
    }
  }
}

.light-style .flatpickr-calendar.open {
  width: 156rem!important;
  z-index: 9999!important;
}

.poipiipiop {
  width: 220rem;
}

.ewdmwepod {
 width: 440rem;
}

.rrich-message__user-template__wrapper__bottom {
  left: 50rem;
}