:root {
  --fontFamilyNotoSans: "Noto Sans Display", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", sans-serif;

  --color-black: #000;
  --color-blue: #1e70cd;
  --color-dark: #4e5155;
  --color-green: #02bc77;
  --color-yellow: #ffd950;
  --color-white: #fff;
  --color-widget: #aaa;
  --color-lighnest-grey: #e8e8e9;
  --color-link-border: #f8f8f8;

  // color rgb
  --color-white-rgb: 225, 225, 225;

  // Borders
  --color-border-default: #ddd;
  --color-border-colored: #rgba (255, 255, 255, 0.2);

  // --color-grey-900
  --color-grey-800: #4d4d4d;
  --color-grey-500: #747474;
  --color-grey-400: #8d8d8d;
  --color-grey-200: #fdfdfd;
  --color-grey-100: #f5f5f5;

  // bodyText
  --bodyText12-18: 400 12rem/18rem var(--fontFamilyNotoSans);
  --bodyText14-24: 400 14rem/24rem var(--fontFamilyNotoSans);
  --title14-24: 500 14rem/24rem var(--fontFamilyNotoSans);
  --title16-24: 500 16rem/24rem var(--fontFamilyNotoSans);
  --title24-32: 500 24rem/32rem var(--fontFamilyNotoSans);

  // DEFAULT THEME

  --colorRGB0: 4, 7, 11;
  --colorRGB1: 9, 17, 28;
  --colorRGB2: 15, 27, 45;
  --colorRGB3: 21, 38, 62;
  --colorRGB4: 26, 48, 79;
  --colorRGB5: 32, 59, 96;
  --colorRGB6: 38, 69, 113;
  --colorRGB7: 44, 79, 130;
  --colorRGB8: 49, 90, 147;
  --colorRGB9: 55, 100, 164;
  --colorRGB10: 61, 110, 181;
  --colorRGB11: 66, 121, 198;
  --colorRGB12: 72, 131, 215;
  --colorRGB13: 78, 141, 232;
  --colorRGB14: 84, 152, 249;
  --colorHEX0: #04070b;
  --colorHEX1: #09111c;
  --colorHEX2: #0f1b2d;
  --colorHEX3: #15263e;
  --colorHEX4: #1a304f;
  --colorHEX5: #203b60;
  --colorHEX6: #264571;
  --colorHEX7: #2c4f82;
  --colorHEX8: #315a93;
  --colorHEX9: #3764a4;
  --colorHEX10: #3d6eb5;
  --colorHEX11: #4279c6;
  --colorHEX12: #4883d7;
  --colorHEX13: #4e8de8;
  --colorHEX14: #5498f9;
}

/** Free card / OwnCard / PaidCard
*
*/
.modal {
  &__close,
  &__share,
  &__heart {
    cursor: pointer;
    color: var(--color-lighnest-grey);

    &:hover {
      color: var(--color-dark);
    }
  }

  &__img {
    height: 120rem;
    background: var(--color-grey-800);
  }

  &__title {
    font: var(--title24-32);
    color: var(--color-dark);
  }

  &__owner {
    font: var(--title14-24);
    color: var(--color-dark);
  }

  &__brief {
    font-weight: 700;
    font-size: 13rem;
    line-height: 16rem;
    color: #747474;
  }

  &__price {
    font: var(--title24-36);
    color: var(--color-dark);
  }

  &__subtitle {
    font: var(--title14-24);
  }

  &__text {
    font: var(--bodyText14-24);

    &__li {
      font: var(--bodyText14-24);
      color: var(--color-grey-400);
    }
  }

  &__btn {
    background: var(--color-blue);
  }

  &__subbtn {
    font: var(--bodyText14-24);
    color: var(--color-blue);
    cursor: pointer;
  }

  li:after {
    color: var(--color-grey-400);
  }
}

.css-fn5tqh {
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  border-width: 0;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  max-width: 100%;
  outline: none !important;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  border-radius: 4px;
  box-shadow: 0 0 0 2rem inherit;
  color: var(--color-grey-400);
  cursor: default;
  height: 32rem;
  line-height: 32rem;
  padding: 0 8rem;
  -webkit-transition: background 0.1s ease-out,
  box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  transition: background 0.1s ease-out,
  box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  -webkit-transition-duration: 0.1s, 0.15s;
  transition-duration: 0.1s, 0.15s;
  vertical-align: middle;
  width: auto;

  &:hover {
    -webkit-box-align: baseline;
    align-items: baseline;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: normal;
    max-width: 100%;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    height: 32rem;
    line-height: 32rem;
    vertical-align: middle;
    width: auto;
    color: var(--color-grey-400);
    border-width: 0rem;
    text-decoration: none;
    background: rgba(9, 30, 66, 0.04);
    border-radius: 4px;
    padding: 0 8rem;
    -webkit-transition: background 0.1s ease-out,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
    transition: background 0.1s ease-out,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
    -webkit-transition-duration: 0.1s, 0.15s;
    transition-duration: 0.1s, 0.15s;
    outline: none !important;
  }
}

.css-j8fq0c {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  position: relative;
}

.css-noix33 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  line-height: 0;
  font-size: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 -2rem;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 1;
}

.jdkWJB {
  height: 24rem;
  width: 24rem;
  color: currentcolor;
  display: inline-block;
  fill: rgb(255, 255, 255);
  flex-shrink: 0;
  line-height: 1;
}

.jdkWJB > svg {
  height: 24rem;
  width: 24rem;
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  vertical-align: bottom;
  overflow: hidden;
}

// Цвет текста и иконок активного окна
.is-active-item {
  // -webkit-box-shadow: 5rem 6rem 20rem 0rem rgba(0, 0, 0, 0.4);
  // -moz-box-shadow: 5rem 6rem 20rem 0rem rgba(0, 0, 0, 0.4);
  // box-shadow: 5rem 6rem 20rem 0rem rgba(0, 0, 0, 0.4);
}

.is-active-item .widget-fox {
  // border: 1rem solid #bbb !important;
}

.is-active-item .widget__header {
  color: #eee !important;
  // background: #888!important;
  margin: -1rem;

  background-color: #888 !important;
  /* fallback color if gradients are not supported */
  background-image: -webkit-linear-gradient(180deg, #aaa, #666) !important;
  /* For Chrome 25 and Safari 6, iOS 6.1, Android 4.3 */
  background-image: -moz-linear-gradient(180deg, #aaa, #666) !important;
  /* For Firefox (3.6 to 15) */
  background-image: -o-linear-gradient(180deg, #aaa, #666) !important;
  /* For old Opera (11.1 to 12.0) */
  background-image: linear-gradient(180deg, #aaa, #666) !important;
  /* Standard syntax; must be last */

  z-index: 9999;
  // box-shadow: 0 3rem 5rem rgba(127, 127, 127, 50%);
  box-shadow: 0 3rem 3rem rgba(127, 127, 127, 0.5);
}

.is-active-item .widget__body {
  // background: red !important;
}

.is-active-item .widget__header .widget__wrapper .card-header-title {
  color: #eee !important;
  font-weight: 500 !important;
}

.is-active-item
.widget__header
.widget__wrapper
.icon-close
.icon-block
.icon-wrapper,
.is-active-item
.widget__header
.widget__wrapper
.icon-settings
.icon-block
.icon-wrapper {
  color: #eee !important;
}

// Цвет текста и иконок неактивного окна
.widget__header .widget__wrapper .card-header-title {
  color: #aaa !important;
  font-weight: 400 !important;
}

.widget__header .widget__wrapper .icon-close .icon-block .icon-wrapper,
.widget__header .widget__wrapper .icon-settings .icon-block .icon-wrapper {
  color: #aaa !important;
}

.is-active-item .card-header-title {
  color: #eee !important;
  font-size: 16rem;
  font-weight: 500 !important;
}

.widget-fox-settings {
  background: #f1f1f1 !important;
}

// .widget-fox .card-header {
//   padding: 0rem !important;
// }

.widget-fox .card-header-title {
  color: #aaa;
  font-size: 16rem;
  font-weight: 500 !important;
}

.chat-blocks {
  // max-height: 624rem;
  height: calc(100vh - 270rem);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0rem;
  }
}

.card-blocks {
  max-width: 485rem;
  padding: 0.625rem 1.25rem;
  // border-radius: 0 0 4rem 4rem;
  border-radius: 0;
  background: #fff !important;
  // border-top: 1rem solid #ddd;
  // border-left: 1rem solid #ddd;
  // border-right: 1rem solid #ddd;
  // border-bottom: 1rem solid #ddd;
  @media (min-width: 960rem) {
    height: 720rem;
  }
}

.nav-tabs-bottom > .tab-content {
  height: calc(100vh - 120rem);
  border-radius: 0;
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0);
  // background: #eee !important;
}

.icon {
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  border-width: 0;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  max-width: 100%;
  outline: none !important;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  border-radius: 4px;
  box-shadow: 0 0 0 2rem inherit;
  // color: var(--color-grey-400);
  cursor: default;
  // padding: 0 8rem;
  -webkit-transition: background 0.1s ease-out,
  box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  transition: background 0.1s ease-out,
  box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  -webkit-transition-duration: 0.1s, 0.15s;
  transition-duration: 0.1s, 0.15s;
  vertical-align: middle;
  width: auto;

  &-24,
  &-24:hover {
    height: 24rem;
    line-height: 24rem;
  }

  &-32,
  &-32:hover {
    height: 32rem;
    line-height: 32rem;
  }

  &-40,
  &-40:hover {
    height: 40rem;
    line-height: 40rem;
  }

  &-48,
  &-48:hover {
    height: 48rem;
    line-height: 48rem;
  }

  &-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &:hover {
    -webkit-box-align: baseline;
    align-items: baseline;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-weight: normal;
    max-width: 100%;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: middle;
    width: auto;
    // color: var(--color-grey-400);
    border-width: 0rem;
    text-decoration: none;
    background: rgba(9, 30, 66, 0.04);
    border-radius: 4px;
    // padding: 0 8rem;
    -webkit-transition: background 0.1s ease-out,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
    transition: background 0.1s ease-out,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
    -webkit-transition-duration: 0.1s, 0.15s;
    transition-duration: 0.1s, 0.15s;
    outline: none !important;
  }
}

.icon-block {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  position: relative;
}

.icon-wrapper {
  color: currentcolor;
  display: inline-block;
  fill: rgb(255, 255, 255);
  flex-shrink: 0;
  line-height: 1;
  height: 24rem;
  width: 24rem;
}

.icon-wrapper > svg {
  height: 24rem;
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  vertical-align: bottom;
  overflow: hidden;
}

.test-body__tabs {
  & > .nav-link {
    padding: 0.325rem 0.625rem !important;
  }
}

.test-body__body {
  &-info,
  &-users,
  &-sources,
  &-output {
    padding: 16rem;
  }

  & > .card-body {
    padding: 0.625rem !important;
  }

  &__header {
    font-size: 18rem;
    font-weight: 300;
    line-height: 30rem;
    margin-bottom: 8rem;
    color: #888888;
  }

  &__desc {
    font-size: 13rem;
    font-weight: 400;
    line-height: 18rem;
    margin-bottom: 8rem;
  }
}

.users-section {
  // position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
}

.users-wrapper {
  margin-bottom: 24rem;
}

.user-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4rem;
}

.user-avatar {
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  margin-right: 8rem;

  &--active {
    background: #3cf72c;
    border: 1px solid #35c628;
  }

  &--default {
    background: #d8d8d8;
    border: 1px solid #979797;
  }
}

.label-dropdown {
  position: relative;
  display: inline-block;
}

.label-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160rem;
  box-shadow: 0rem 8rem 16rem 0rem rgba(0, 0, 0, 0.2);
  padding: 12rem 16rem;
  z-index: 1;
  right: -48rem;
  width: 320rem;
}

.label-dropdown:hover .label-dropdown-content {
  display: block;
}

.rich-message__body__center {
  position: relative;
  width: 100%;
  border: 1px solid #eee;
  margin: 8rem 0;
}

.rm-toolbars {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10rem;
  justify-content: space-between;
}

.rm {
  cursor: pointer;
}

.rm-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rm-system {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rm-spacer {
  margin: 0 8rem;
}

.rich-message__body__left__bottom .rdw-editor-wrapper .rdw-editor-toolbar {
  display: none;
}

.rich-message__editor {
  border: 1px solid #eee;
}

.rich-message__editor-preview {
  margin: 16rem 0;
}

.twitter-picker {
  width: 312rem !important;

  & div > div:nth-last-of-type(3),
  & div > input {
    display: none !important;
  }
}

.btn-custom {
  display: inline-block;
  height: 24rem;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #32c864;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;

  &__title {
    color: #fff;
    font-size: 14rem;
    font-weight: 900;
    line-height: 24rem;
    padding: 0rem 24rem 0rem 8rem;
  }

  &__img {
    width: 24rem;
    height: 24rem;
  }
}

.btn-c-sm {
  padding: 0rem 6rem !important;
  font-size: 12rem !important;
  font-weight: 600 !important;
  line-height: 18rem !important;
  border-radius: 3px !important;
}

.codex-editor__redactor {
  padding-bottom: 0rem !important;
}

.rm-dropdown .dropdown-menu {
  box-shadow: 0 2rem 4rem 0 rgba(0, 0, 0, 0.2);
}

.rich-message__preview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rich-message__block {
  max-width: 320rem;
  min-width: 160rem;
  width: 100%;
}

.btn-custom__time {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40rem;
  width: 100%;
  max-width: 150rem;
  border: 1px solid #979797;
  border-radius: 4px;
}

.c-container {
  width: 100%;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.c-avatar-color {
  width: 80px;
  height: 80px;
}

.circle-radius {
  width: 30px;
  height: 30px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

// validations
.hidden {
  display: none;
}

.inline-errormsg {
  color: red;
  font-size: 12rem;
}

.inline-donemsg {
  color: green;
  font-size: 12rem;
}

.inline-errormsg-btn {
  padding-bottom: 17rem;
}

//uppercase in Roles and Permissions

.role-permissions td::first-letter {
  text-transform: uppercase;
}

.modal-content {
  box-shadow: 5rem 6rem 20rem 0rem rgba(0, 0, 0, 0.4) !important;
}

.box {
  float: left;
}

// emoji-mart

.emoji-mart {
  border-radius: 0 5px 0 0 !important;
  position: absolute;
  bottom: 59rem;
  width: 298rem !important;
  z-index: 9999;
}

.emoji-mart-category .emoji-mart-emoji span {
  cursor: pointer !important;
}

.progress-bar {
  width: 385rem;
  display: flex;
  height: 0.75rem;
  overflow: hidden;
  font-size: 0.625rem;
  background-color: rgb(227, 228, 230);
  border-radius: 10px;
}

:focus {
  outline: none !important;
}

.tv-embed-widget-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.tv-mini-symbol-overview__link
.js-link
.tv-mini-symbol-overview__link--with-hover {
  width: 100% !important;
  height: 100% !important;
}

.widget__extra__wrapper article {
  position: absolute;
  // padding: 1rem;
  // padding-right: 2rem;
  width: 100% !important;
  height: 100% !important;
}

.widget-extra .card {
  background-color: rgba(0, 0, 0, 0);
}

.extra-widget-container {
  width: 100% !important;
  height: 100% !important;
}

.extra-widget-container iframe {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}

.extra-widget-container__widget > div {
  width: 100% !important;
  height: 100% !important;
}

//tabs
.nav-tabs {
  .nav-link {
  }
}

// preference
.input-pr input {
  display: block;
  width: 40rem;
  height: calc(1.1em + 0.876rem + 2rem);
  padding: 2rem 6rem;
  font-size: 15rem;
  font-weight: 500;
  line-height: 1;
  color: #4e5155;
  background-color: #fff;
  background-clip: padding-box;
  border: 1rem solid rgba(24, 28, 33, 0.1);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.workspace__form-error-input input {
  border: 1rem solid #ff0000;
}

@mixin button(
  $class-name: "button",
  $padding: 0.5rem,
  $border-radius: 0,
  $outline: 1rem solid #000,
  $background-color: #ccc,
  $color: #000,
  $box-shadow: 0rem 4rem 4rem #000
) {
  .#{$class-name} {
    padding: $padding;
    @if $border-radius > 0 {
      border-radius: $border-radius;
    }
    outline: $outline;
    color: $color;
    background-color: rgba($background-color, 0.2);
    box-shadow: $box-shadow;
    transition: 0.25s background-color ease;

    font-size: 20rem;
    line-height: 29rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;

    svg {
      fill: $color;
      margin-right: 8rem;
    }

    &:hover {
      background-color: rgba($background-color, 0.35);
    }

    &:focus {
      background: rgba(var(--colorRGB2), 0.3);
      outline: 1rem solid #0f1b2d !important;
    }

    &:active {
      background: rgba(var(--colorRGB2), 0.3);
      outline: 1rem solid #0f1b2d;
    }

    &:disabled {
      background-color: none;
      color: rgba(255, 255, 255, 0.3);
      cursor: not-allowed;

      svg {
        fill: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

@include button(
                "n-button-x-large",
                8rem 20rem,
                4rem,
                1rem solid rgba(var(--colorRGB2), 0.3),
                var(--color-white-rgb),
                #fff,
                none
);


html {
  --htmlFontRoot: 1px;

  &.scale {
    font-size: var(--htmlFontRoot) !important;

    ::-webkit-scrollbar {
      width: 8rem;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 100rem;
      border-radius: 100rem;
    }

    body {
      font-size: 14.3rem !important;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background: rgba(var(--colorRGB11), 0.30);
    // @include transition(background);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--colorRGB11), 0.35);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(var(--colorRGB4), 1);
  }

  ::-ms-expand{
    width: 100px;
  }
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content{
  min-height: 190px!important;
}

.overlap-widgets{
  //&::after{
    position: fixed;
    background: transparent;
    //background: rgba(0,0,0,0.4);
    left: 0;
    right: 0;
    bottom: 0;
  //}
}

//
//.amplify-form-custom{
//    --amplify-text-xxs: 14.3 * 0.75rem;
//    --amplify-text-xs: 14.3 * 0.81rem;
//    --amplify-text-sm: 14.3 * 0.875rem;
//    --amplify-text-md: 14.3 * 1rem;
//    --amplify-text-lg: 14.3 * 1.5rem;
//    --amplify-text-xl: 14.3 * 2rem;
//    --amplify-text-xxl: 14.3 * 2.5rem;
//}