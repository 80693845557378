.copytrading-connect-modal {
  .modal-content {
    height: 430rem!important;
    width: 750rem!important;
    left: 50rem;
    top: 330rem;
    transform: translate(-50%,-50%);
  }
  &__wrapper {
    align-items: center;
    padding: 15rem;
  }
  &__header {
    height: 41rem;
    margin-bottom: 8rem;
    display: flex;
    justify-content: space-between;
    &__title {
      display: flex;
      justify-content: flex-end;
      font-weight: 300;
      font-size: 36rem;
      line-height: 39rem;
      color: #4E5155;
      cursor: default;
      &--close {
        display: none;
      }
      &--bold {
        font-weight: 500;
      }
    }
  }
  &__body {
    padding: 34rem 0 0 32rem;
    &__show {
      display: block;
    }
    &__close {
      display: none;
    }
    &__subheader {
      padding: 0 20rem 20rem 0;
      font-size: 24rem;
      font-weight: 400;
      border-color: #fff;
    }
    &__source {
      padding: 20rem 20rem 0 0;
      font-size: 18rem;
      font-weight: 400;
      border-color: #fff;
      &__description {
        padding: 0 20rem 20rem 0;
        font-size: 14rem;
        font-weight: 300;
        border-color: #fff;
      }
      &__control {
        padding: 20rem 20rem 20rem 0;
        border-color: #fff;
        display: flex;
      }
      &__control2 {
        border: 1rem solid rgba(24, 28, 33, 0.1);
      }
    }
    &__action {
      width: 380rem;
      display: flex;
      justify-content: center;
      padding: 42rem 20rem 20rem 146rem;
      font-size: 24rem;
      font-weight: 400;
      border-color: #fff;
    }
  }
}

.poipiipiop {
  width: 220rem;
}

.ewdmwepod {
 width: 440rem;
}